import { webClient } from "@api/web/WebClient";
import CollapseBox from "@components/web/CollapseBox";
import { withWebLayout } from "@components/web/Decorators";
import PaymentButton from "@components/web/financial/PaymentButton";
import PriceTag from "@components/web/financial/PriceTag";
import FooterWithPaymentIcons from "@components/web/FooterWithPaymentIcons";
import { FrontPageLayout } from "@components/web/layouts/FrontPageLayout";
import { useLocalization } from "@hooks/localization";
import { useQueryParameters } from "@hooks/navigation";
import { useIsVisible } from "@hooks/visibleOnScroll";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { WebReepayPlansAvailableForSignupPlanGetResponse, WebReepayPlusIsActiveGetResponse } from "@reshopper/web-client";
import i18n from "@utils/i18n";
import { navigateTo404 } from "@utils/navigation";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import CheaperShippingIcon from "../images/layout/plus/cheaper-shipping.svg";
import CreditsIcon from "../images/layout/plus/credits.svg";
import PlusFamilyLogo from "../images/layout/plus/plus-family-logo.svg";
import SerciveFeeIcon from "../images/layout/plus/service-fee.svg";
import SurplusItemsIcon from "../images/layout/plus/surplus-items.svg";
import ShineIcon from "../images/layout/shine.svg";
import LogoBeigeIcon from "../images/newFrontpage/logo/logo-beige-icon.svg";
import LogoWhiteIcon from "../images/newFrontpage/logo/logo-white-icon.svg";
import styles from "./index.module.scss";

type QueryParameters = {
	userId: string;
	planId: string;
	couponCode: string;
	referrerUserId?: string
};

export default withWebLayout(function PlusPage() {
	const queryParameters = useQueryParameters<QueryParameters>();
	const [plusFamilyPlan, setPlusFamilyPlan] = useState<WebReepayPlansAvailableForSignupPlanGetResponse | null | undefined>();
	const [hasActivePlus, setHasActivePlus] = useState<WebReepayPlusIsActiveGetResponse>();
	const t = useLocalization();


	useEffect(() => {

		const fetchSubscriptionsAsync = async () => {
			try {
				const languageResponse = await webClient().webUsersLanguageGet();

				const language = languageResponse.language;
				if (i18n.language !== language) {
					i18n.changeLanguage(language);
				}

				const plansAvailableForSignup = await webClient().webReepayPlansAvailableForSignupGet();

				const plusFamilyPlan = plansAvailableForSignup.plans.find(x => x.handle === "reepay_subscription_1");
				setPlusFamilyPlan(plusFamilyPlan ?? null);

				const hasActivePlusResponse = await webClient().webReepayPlusIsActiveGet();
				setHasActivePlus(hasActivePlusResponse)
			} catch (error) {
				navigateTo404();
			}
		};
		fetchSubscriptionsAsync();
	}, [queryParameters]);



	if (plusFamilyPlan === undefined || !hasActivePlus) {
		return <PlusPageTemplate>
			<Box textAlign="center" paddingTop={"30px"}>
				<CircularProgress title="Loading" aria-label="Loading" />
			</Box>
		</PlusPageTemplate>
	}

	if (plusFamilyPlan === null) {
		navigateTo404();
	}

	return <PlusPageTemplate>
		<Typography className={styles.plusSubHeadline}>
			{t("Plus_Signup_Family_Subheadline")}
		</Typography>
		<Box className={styles.plusPopularBenefitsContainer}>
			<img src={ShineIcon} className={styles.imgShine} width="249" height="216" alt="shine" />
			<Box className={styles.content}>
				<Box style={{ flex: "0 1 310px" }}>
					<img src={CheaperShippingIcon} width="84" height="79" alt={t("Plus_Signup_Family_LowerShippingPrices_Title")} />
					<Typography variant="h3">{t("Plus_Signup_Family_LowerShippingPrices_Title")}</Typography>
					<Typography>{t("Plus_Signup_Family_LowerShippingPrices_Subheader")}</Typography>
				</Box>
				<Box style={{ flex: "0 1 310px" }}>
					<img src={SerciveFeeIcon} width="84" height="79" alt={t("Plus_Signup_Family_ServiceFeeSaving_Title")} />
					<Typography variant="h3">{t("Plus_Signup_Family_ServiceFeeSaving_Title")}</Typography>
					<Typography>{t("Plus_Signup_Family_ServiceFeeSaving_Subheader")}</Typography>
				</Box>
				<Box style={{ flex: "0 1 310px" }}>
					<img src={CreditsIcon} width="84" height="79" alt={t("Plus_Signup_Family_FeatureCreditsPerMont_Title")} />
					<Typography variant="h3">{t("Plus_Signup_Family_FeatureCreditsPerMont_Title")}</Typography>
					<Typography>{t("Plus_Signup_Family_FeatureCreditsPerMont_Subheader")}</Typography>
				</Box>
				<Box style={{ flex: "0 1 310px" }}>
					<img src={SurplusItemsIcon} width="84" height="79" alt="Billigere fragt" />
					<Typography variant="h3">{t("Plus_Signup_Family_RetailSavings_Header")}</Typography>
					<Typography>{t("Plus_Signup_Family_RetailSavings_Subheader")}</Typography>
				</Box>
			</Box>
		</Box>
		<AllBenefits />
		<Box className={styles.plusHelpContainer}>
			<Box>
				<Box className={styles.logoIcon}>
					<img alt="Reshopper" src={LogoBeigeIcon} />
				</Box>
				<Typography variant="h2">{t("Plus_Signup_Family_FAQ_Title")}</Typography>
				<Box>
					<CollapseBox
						title={t("Plus_Signup_Family_Help_Title_Member")}
						text={t("Plus_Signup_Family_Help_Text_Member")} />
					<CollapseBox
						title={t("Plus_Signup_Family_Help_Title_MemberPricer")}
						text={t("Plus_Signup_Family_Help_Text_MemberPricer")} />
					<CollapseBox
						title={t("Plus_Signup_Family_Help_Title_BindingPeriod")}
						text={t("Plus_Signup_Family_Help_Text_BindingPeriod")} />
					<CollapseBox
						title={t("Plus_Signup_Family_Help_Title_CancelPlus")}
						text={<span dangerouslySetInnerHTML={{
							__html: t("Plus_Signup_Family_Help_Text_CancelPlus", {
								mail: "support@reshopper.com",
							}),
						}} />} />
				</Box>
			</Box>
		</Box>

		<Slide
			direction="up"
			in={!hasActivePlus.hasActiveMongoSubscription}
			timeout={700}
			mountOnEnter
			unmountOnExit>
			<Box className={styles.signUpButtonContainer}>
				<Container className={styles.content}>
					{!hasActivePlus.hasActiveMongoSubscription &&
						<SignupForPlusButton
							plan={plusFamilyPlan!}
							variant={"primary"}
						/>
					}
				</Container>
			</Box>
		</Slide>

		<FooterWithPaymentIcons style={{ backgroundColor: "#fff" }} />
		<Box className={styles.whiteSpace} />
		<Dialog
			open={
				(!hasActivePlus.hasActiveMongoSubscription && hasActivePlus.hasActiveReepaySubscription) ||
				(hasActivePlus.hasActiveMongoSubscription && !hasActivePlus.hasActiveReepaySubscription)}
		>
			<DialogTitle>
				Error
			</DialogTitle>
			<DialogContent>
				{t("Plus_Signup_Family_Error_Title_SubscriptionIssue")}
			</DialogContent>
		</Dialog>
	</PlusPageTemplate>
});


function PlusPageTemplate(props: {
	children: React.ReactNode
}): JSX.Element {
	const t = useLocalization();
	return <>
		<FrontPageLayout removeBlueBodyBackground>
			<Helmet
				title="Reshopper Plus Family">
				<meta name="description" content={"Bliv en del af Reshopper Plus Family og få skønne fordele som: billigere fragt, lavere servicegebyr, rabat på overskudsvarer, credits og meget mere."} />
				<meta name="robots" content="index, follow" />
			</Helmet>

			<Container className={styles.newPlusPage}>
				<Box className={styles.plusHeader}>
					<Box style={{ paddingBottom: "15px" }}>
						<img alt="Reshopper" src={LogoWhiteIcon} />
					</Box>
					<img
						src={PlusFamilyLogo}
						width="531"
						height="60"
						alt="Reshopper Plus Family"
						style={{ width: "100%", height: "auto", maxWidth: "460px" }} />
				</Box>
				{props.children}
			</Container>
		</FrontPageLayout>
	</>
};


function SignupForPlusButton(props: {
	plan: WebReepayPlansAvailableForSignupPlanGetResponse;
	text?: string;
	variant: "primary" | "secondary";
}) {
	const t = useLocalization();
	const params = useQueryParameters<QueryParameters>();
	const onPurchase = () => {
		window.location.href = "/plus/signup-success";
	};
	return (
		<>
			<div className={styles.signUpButton}>
				<PaymentButton
					variant={props.variant}
					style={{ marginBottom: 10 }}
					onPurchase={onPurchase}
					uniqueId={props.plan.handle}
					sessionType="subscription"
					subscriptionInfo={{
						subscriptionPlanId: props.plan.handle,
						couponCode: params.couponCode,
					}}
					referrerUserId={params.referrerUserId}
				>
					{props.text ? (
						props.text
					) : (
						<>
							{t("Plus_Signup_Family_SignUpButton")}
						</>
					)}
				</PaymentButton>
				<PriceTag
					initialPrice={props.plan.priceInHundreds / 100}
					variant="compact"
					currencySuffix={
						<>
							/
							{props.plan.lengthInMonths === 12
								? t("Plus_Signup_Content_ShortYear")
								: t(
									"Plus_Signup_Content_ShortMonth"
								)}
						</>
					}
				/>
				<Typography className={styles.note}>{t("Plus_Signup_Family_SignUpButton_ExtraText")}</Typography>
			</div>
		</>
	);
};

function AllBenefits() {
	const t = useLocalization();
	const ref = useRef<any>(null);
	const isVisible = useIsVisible(ref);
	const showInfo = false;

	return <Box className={styles.plusAllBenefitsContainer}>
		<Typography variant="h2">{t("Plus_Signup_Family_AllBenefits_Title")}</Typography>
		<div ref={ref}>
			<CollapseBox
				title={t("Plus_Signup_Family_LowerShippingPrices_Title")}
				text={<span dangerouslySetInnerHTML={{
					__html: t("Plus_Signup_Family_LowerShippingPrices_Text", {
						link: "https://reshopperdk.helpscoutdocs.com/article/50-priser-stoerrelser-leveringstid-reshopper-fragt",
					}),
				}} />}
				checkIcon
				index={1}
				visibleOnScroll={isVisible}
			/>
			<CollapseBox
				title={t("Plus_Signup_Family_RetailSavings_Title")}
				text={<span dangerouslySetInnerHTML={{ __html: t("Plus_Signup_Family_RetailSavings_Text") }} />}
				note={t("Plus_Signup_Family_Terms_One")}
				checkIcon
				index={2}
				visibleOnScroll={isVisible} />
			<CollapseBox
				title={t("Plus_Signup_Family_FeatureCreditsPerMont_Title")}
				text={<span dangerouslySetInnerHTML={{ __html: t("Plus_Signup_Family_FeatureCreditsPerMont_Text") }} />}
				note={t("Plus_Signup_Family_Terms_Two")}
				checkIcon
				index={3}
				visibleOnScroll={isVisible} />
			<CollapseBox
				title={t("Plus_Signup_Family_ServiceFeeSaving_Title")}
				text={<Trans
					i18nKey="Plus_Signup_Family_ServiceFeeSaving_Text"
					values={{ link: "https://reshopper.com/koeberbeskyttelse" }}
					components={{ a: <a /> }}
				/>}
				checkIcon
				index={4}
				visibleOnScroll={isVisible} />
			<CollapseBox
				title={t("Plus_Signup_Family_NoAdsInApp_Title")}
				text={<span dangerouslySetInnerHTML={{ __html: t("Plus_Signup_Family_NoAdsInApp_Text") }} />}
				checkIcon
				index={5}
				visibleOnScroll={isVisible} />
			<CollapseBox
				title={t("Plus_Signup_Family_FeaturePrioritizedItems_Title")}
				text={t("Plus_Signup_Family_FeaturePrioritizedItems_Text")}
				checkIcon
				index={6}
				visibleOnScroll={isVisible} />
			<CollapseBox
				title={t("Plus_Signup_Family_Credibility_Title")}
				text={t("Plus_Signup_Family_Credibility_Text")}
				checkIcon
				index={7}
				visibleOnScroll={isVisible} />
			{showInfo &&
				<CollapseBox
					title={t("Plus_Signup_Family_ResaleService_Title")}
					text={t("Plus_Signup_Family_ResaleService_Text")}
					checkIcon
					index={8}
					visibleOnScroll={isVisible} />
			}
		</div>
	</Box>
};